/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { AsideMenu2 } from "./aside-menu2/AsideMenu2";
import { AsideMenu3 } from "./aside-menu3/AsideMenu3";
import { AsideMenu4 } from "./aside-menu4/AsideMenu4";
import { AsideMenu5 } from "./aside-menu5/AsideMenu5";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";
import { FormattedMessage } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import jwt from "jwt-decode";
export function Aside() {
  const uiService = useHtmlClassService();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState("");
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  async function getScopes() {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const data = jwt(accessToken);

    setPermissions(data.permissions);
    setLoading(false);
    return;
  }
  useEffect(() => {
    getScopes();
  }, []); // <-- empty dependency array
  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
    tabId3: "kt_aside_tab_3",
    tabId4: "kt_aside_tab_4",
    tabId6: "kt_aside_tab_6",
  };
  const [activeTab, setActiveTab] = useState(tabs.tabId1);
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Assets"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="latest-project">
                      {" "}
                      <FormattedMessage id="MENU.DASHBOARD" />
                    </Tooltip>
                  }
                >
                  <a
                    href="#"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                      tabs.tabId1 && "active"}`}
                    data-toggle="tab"
                    data-target={`#${tabs.tabId1}`}
                    role="tab"
                    onClick={() => handleTabChange(tabs.tabId1)}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Layout/Layout-4-blocks.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              {/* end::Item */}

              {/* begin::Item */}
              {JSON.stringify(permissions).search("generic:access") > 0 ? (
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Εργαλεία Οικιστικών</Tooltip>
                    }
                  >
                    <a
                      href="#"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                        tabs.tabId2 && "active"}`}
                      data-toggle="tab"
                      data-target={`#${tabs.tabId2}`}
                      role="tab"
                      onClick={() => handleTabChange(tabs.tabId2)}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Bed.svg")}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                </li>
              ) : (
                <></>
              )}
              {/* end::Item */}

              {/* begin::Item */}

              {/* end::Item */}

              {/* begin::Item */}
              {JSON.stringify(permissions).search("generic:access") > 0 ? (
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">
                        Εργαλεία πολλαπλων χρήσεων
                      </Tooltip>
                    }
                  >
                    <a
                      href="#"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                        tabs.tabId4 && "active"}`}
                      data-toggle="tab"
                      data-target={`#${tabs.tabId4}`}
                      role="tab"
                      onClick={() => handleTabChange(tabs.tabId4)}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Polygon.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                </li>
              ) : (
                <></>
              )}
              {/* end::Item */}
              {JSON.stringify(permissions).search("bog") > 0 ? (
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">
                        Εργαλεία Bank Of Greece
                      </Tooltip>
                    }
                  >
                    <a
                      href="#"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                        tabs.tabId6 && "active"}`}
                      data-toggle="tab"
                      data-target={`#${tabs.tabId6}`}
                      role="tab"
                      onClick={() => handleTabChange(tabs.tabId6)}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Home/Building.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                </li>
              ) : (
                <></>
              )}
              {/* begin::Item */}
              {JSON.stringify(permissions).search("bogaaaa") > 0 ? (
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Εργαλείοθηκη</Tooltip>
                    }
                  >
                    <a
                      href="#"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                        tabs.tabId5 && "active"}`}
                      data-toggle="tab"
                      data-target={`#${tabs.tabId5}`}
                      role="tab"
                      onClick={() => handleTabChange(tabs.tabId5)}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Patch.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                </li>
              ) : (
                <></>
              )}
              {/* end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {layoutProps.asideSecondaryDisplay &&
              layoutProps.asideSelfMinimizeToggle && (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                  >
                    <span
                      className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                      id="kt_aside_toggle"
                    >
                      <i className="ki ki-bold-arrow-back icon-sm" />
                    </span>
                  </OverlayTrigger>
                </>
              )}
            {/* end::Aside Toggle */}

            {/* begin::Notifications */}
            {layoutProps.extrasNotificationsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-notifications">Notifications</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_notifications_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Notifications */}

            {/* begin::Languages*/}
            {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
            {/* end::Languages */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  <AsideSearch isActive={activeTab === tabs.tabId1} />
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                  <AsideMenu2 isActive={activeTab === tabs.tabId3} />
                  <AsideMenu3 isActive={activeTab === tabs.tabId4} />
                  <AsideMenu4 isActive={activeTab === tabs.tabId5} />
                  <AsideMenu5 isActive={activeTab === tabs.tabId6} />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
