/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard Οικιστικών</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">AssetDB</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Tools</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/avm/assets">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">AVM</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/residential-wizard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Indexation</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/residential-wizard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Forecast</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/test-page">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Δημογραφικά</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/test-page">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Marketability / Time to Sell</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/test-page">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Location Scores</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/test-page">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Market Reports</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/* Custom */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/residential-wizard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Αναφορές</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
